<template>
  <WeModal icon="fas fa-file-excel" v-bind:title="title" v-on:close="onClose">
    <div slot="body">
      <!-- Info -->
      <WeCard class="alert-info mb-3" v-if="file.files.length">
        <i class="fas fa-info-circle mr-2"></i>
        <span
          >Güncellemek istediğiniz sütunları <b>Aktif</b> duruma getirin</span
        >
      </WeCard>
      <!-- ./Info -->

      <template v-if="useLanguage">
        <div class="mb-3">
          <label class="custom-label">İçerik Dili Seçimi</label>
          <div class="d-block mb-1">
            <small class="text-muted"
              >Yükleyeceğiniz ürünlerin hangi dilde oluşturulacağını veya
              güncelleneceğini belirler.</small
            >
          </div>
          <WeLanguageSwitcher
            v-bind:value="fileLanguage"
            v-on:input="onChangeLanguage"
          />
        </div>
      </template>

      <!-- File Uploader -->
      <WeFileUploader v-model="file" meta-visible="" sizeLimit="20480" />
      <!-- ./File Uploader -->

      <div v-show="file.files.length">
        <!-- Fields -->
        <WeSwitch label="Tümünü Seç" v-model="useAllFields" />
        <div class="row mt-3">
          <div
            class="col-12 col-md mb-2 mb-md-0 custom-scrollbar"
            style="max-height: 350px; overflow: auto"
          >
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="field in getFields"
                v-bind:key="field.key"
              >
                <WeSwitch
                  v-bind:label="field.value"
                  v-bind:value="getSelected(field)"
                  v-bind:disabled="checkSelectable(field)"
                  v-on:input="onSwitch(field, $event)"
                />
              </li>
            </ul>
          </div>
        </div>
        <!-- ./Fields -->

        <div class="text-center mt-3">
          <WeSubmitButton
            v-bind:submit-status="submit"
            btn-text="Gönder"
            v-on:submit="onClose(true)"
          />
        </div>
      </div>
    </div>
  </WeModal>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "WeBulkImport",
  data() {
    return {
      file: {
        files: [],
        filesData: null,
      },
      selected: [],
      useVariant: false,
      useAllFields: false,
    };
  },
  props: {
    title: {
      default: "Toplu Ürün Yükleme Sihirbazı",
    },
    fieldKey: {
      default: "product",
    },
    fixedSelected: {
      default: () => [],
    },
    requiredKeys: {
      default: () => [],
    },
    isVariant: {
      default: false,
    },
    submit: {
      default: false,
    },
    useLanguage: {
      default: false,
    },
    fileLanguage: {
      default: "tr",
    },
  },
  methods: {
    onChangeLanguage(e) {
      if (this.useLanguage) {
        this.$emit("update:fileLanguage", e);
      }
    },
    onSwitch(field, status) {
      if (status) {
        this.selected.push(field);
      } else {
        const selectedIndex = this.selected.findIndex(
          (item) => item.key === field.key
        );
        this.selected.splice(selectedIndex, 1);
      }
    },
    getSelected(field) {
      return this.selected.filter((item) => item.key == field.key).length;
    },
    setSelected() {
      if (this.fixedSelected.length) {
        this.fixedSelected.forEach((key) => {
          const fieldFromStore = this.getFields.find((item) => item.key == key);
          if (!this.getSelected(fieldFromStore)) {
            const value = helper.clone(fieldFromStore);
            this.selected.push(value);
          }
        });
      }
    },
    onClose(process = false) {
      const data = {
        process: process,
        selected: this.selected,
        use_variant: this.useVariant,
        file: this.file,
      };

      this.$emit("close", data);
    },
    checkSelectable(field) {
      // Zorunlu olan alanlarda disabled=true olacak
      return this.requiredKeys.includes(field.key);
    },
  },
  computed: {
    ...mapState(["listimport"]),
    getFields() {
      return helper.clone(this.listimport.fields[this.fieldKey]);
    },
  },
  watch: {
    useAllFields: function (status) {
      if (status) {
        this.selected = this.getFields;
        this.useVariant = true;
      } else {
        this.selected = [];
        this.setSelected();
        this.useVariant = false;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.setSelected();
    }, 100);
  },
};
</script>